import React, { useEffect } from "react"
import styles from "./NewsList.module.scss"
import Layout from "../../components/layout/Layout"
import Header from "../../components/Header/Header"
import NewsItem from "../../components/news/NewsItem"
import { useDispatch, useSelector } from "react-redux"
import { getAllNews } from "../../state/newsSlice"

export default function NewsList() {
  const dispatch = useDispatch()

  const { newsList } = useSelector((state) => state.news)

  useEffect(() => {
    dispatch(getAllNews())
  }, [dispatch])

  return (
    <main>
      <Layout />
      <Header />
      <h1 className={styles.header_text}>Compuone News</h1>
      <div className={styles.news_list}>
        {Object.entries(newsList).map(([key, value]) => (
          <NewsItem key={key} data={value} />
        ))}
      </div>
    </main>
  )
}
