import {
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit"
import {
  createNewsRequest,
  getAllNewsRequest,
  getNewsByIdRequest,
  removeNewsRequest,
} from "../api/news"

const initialState = {
  newsList: [],
  news: null,
  loading: false,
  error: null,
}

export const createNews = createAsyncThunk(
  "news/create",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token
      const response = await createNewsRequest(token, payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getAllNews = createAsyncThunk(
  "news/getAll",
  async (_, thunkAPI) => {
    try {
      const response = await getAllNewsRequest()
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getNewsById = createAsyncThunk(
  "news/getOne",
  async (id, thunkAPI) => {
    try {
      const response = await getNewsByIdRequest(id)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const removeNews = createAsyncThunk(
  "news/remove",
  async (id, thunkAPI) => {
    try {
      const response = await removeNewsRequest(id)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    clearNews: (state) => {
      state.news = null
    },
    setNews: (state, action) => {
      console.log(action.payload)
      state.news = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNews.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(createNews.fulfilled, (state, action) => {
      state.loading = false
      let newsList = [...current(state.newsList)]
      newsList.push(action.payload)
      state.newsList = newsList
    })
    builder.addCase(createNews.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getAllNews.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAllNews.fulfilled, (state, action) => {
      state.loading = false
      state.newsList = action.payload
    })
    builder.addCase(getAllNews.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getNewsById.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getNewsById.fulfilled, (state, action) => {
      state.loading = false
      state.news = action.payload
    })
    builder.addCase(getNewsById.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(removeNews.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(removeNews.fulfilled, (state, action) => {
      state.loading = false
      let newsList = [...current(state.newsList)]
      newsList = newsList.filter(
        (news) => news._id !== action.payload._id
      )
      state.newsList = newsList
    })
    builder.addCase(removeNews.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const { clearNews, setNews } = newsSlice.actions

export default newsSlice.reducer
