import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Footer from "../../components/footer/Footer"
import Header from "../../components/Header/Header"
import ProductsList from "../../components/ProductsList/ProductsList"

import styles from "./ProductsPage.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { getBrands } from "../../state/brandSlice"
import DriverList from "../../components/driver/DriverList"
import { Helmet, HelmetProvider } from "react-helmet-async"
import CatalogueList from "../../components/catalogue/CatalogueList"
import Layout from "../../components/layout/Layout"
import { deFormatUrl, formatUrl } from "../../helpers/Formats"
import { getMetaInformation } from "../../state/categoriesSlice"

export default function ProductsPage(props) {
  const dispatch = useDispatch()
  const { brands } = useSelector((state) => state.brands)
  const { metaData, isLoading } = useSelector((state) => state.categories)
  const [sort, setSort] = useState("relevance")
  const [brand, setBrand] = useState("all")
  let params = useParams()

  //Meta Information

  const [metaTitle, setMetaTitle] = useState("")
  const [metaDescription, setMetaDescription] = useState("")
  const [metaKeywords, setMetaKeywords] = useState("")

  let category = params.category
  category = deFormatUrl(category)

  let subCat
  if (props.sub) {
    subCat = params.subCat
    subCat = deFormatUrl(subCat)
  }

  useEffect(() => {
    dispatch(getBrands())
  }, [dispatch])

  function renderList() {
    if (subCat === "Drivers") {
      return <DriverList brand={brand} />
    } else if (subCat === "Manual") {
      return <CatalogueList brand={brand} />
    } else {
      return (
        <ProductsList
          brand={brand}
          sort={sort}
          cat={category}
          subCat={subCat}
        />
      )
    }
  }

  useEffect(() => {
    dispatch(getMetaInformation(category))
  }, [category])

  useEffect(() => {
    if (metaData) {
      if (subCat) {
        metaData.subMeta.forEach((item) => {
          let tempName = formatUrl(item.name)
          tempName = deFormatUrl(tempName)
          if (tempName === subCat) {
            setMetaDescription(item.metaDescription)
            setMetaKeywords(item.metaKeywords)
            setMetaTitle(item.metaTitle)
          }
        })
      } else {
        setMetaDescription(metaData.description)
        setMetaKeywords(metaData.keywords)
        setMetaTitle(metaData.title)
      }
    }
  }, [metaData, subCat, category, isLoading])

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{metaTitle}</title>
          <link
            rel="canonical"
            href={
              subCat
                ? process.env.REACT_APP_URL +
                  "/" +
                  formatUrl(category) +
                  "/" +
                  formatUrl(subCat) +
                  "/1/"
                : process.env.REACT_APP_URL + "/" + formatUrl(category) + "/1/"
            }
          />
          <link
            rel="next"
            href={
              subCat
                ? process.env.REACT_APP_URL +
                  "/" +
                  formatUrl(category) +
                  "/" +
                  formatUrl(subCat) +
                  `/${parseInt(params.page) + 1}`
                : process.env.REACT_APP_URL +
                  "/" +
                  formatUrl(category) +
                  `/${parseInt(params.page) + 1}`
            }
          />
          {parseInt(params.page) === 1 ? null : (
            <link
              rel="prev"
              href={
                subCat
                  ? process.env.REACT_APP_URL +
                    "/" +
                    formatUrl(category) +
                    "/" +
                    formatUrl(subCat) +
                    `/${parseInt(params.page) - 1}`
                  : process.env.REACT_APP_URL +
                    "/" +
                    formatUrl(category) +
                    `/${parseInt(params.page) - 1}`
              }
            />
          )}
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
          {/* we need to add the open graph tags */}
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta
            property="og:url"
            content={
              subCat
                ? process.env.REACT_APP_URL +
                  "/" +
                  formatUrl(category) +
                  "/" +
                  formatUrl(subCat) +
                  "/1"
                : process.env.REACT_APP_URL + "/" + formatUrl(category) + "/1"
            }
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://www.compuonelb.com/static/media/logo.704b86f20af085db0b41.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta
            name="twitter:url"
            content={
              subCat
                ? process.env.REACT_APP_URL +
                  "/" +
                  formatUrl(category) +
                  "/" +
                  formatUrl(subCat) +
                  "/1"
                : process.env.REACT_APP_URL + "/" + formatUrl(category) + "/1"
            }
          />
          <meta
            name="twitter:image"
            content="https://www.compuonelb.com/static/media/logo.704b86f20af085db0b41.png"
          />
        </Helmet>
      </HelmetProvider>
      <Header />
      <main className={styles.mainContainer}>
        <Layout />
        <div className={styles.header}>
          <h1 className={styles.header_text}>{subCat || category}</h1>

          <div className={styles.sort_options}>
            <select
              onChange={(e) => setBrand(e.target.value)}
              className={styles.sort_container + " " + styles.no_border}
            >
              <option value="all">Any brand</option>
              {Object.entries(brands).map(([key, brand]) => {
                return (
                  <option key={key} value={brand.brand.toLowerCase()}>
                    {brand.brand}
                  </option>
                )
              })}
            </select>
            {subCat === "Drivers" || subCat === "Catalogs" || (
              <select
                onChange={(e) => setSort(e.target.value)}
                className={styles.sort_container}
              >
                <option value="relevance">Sort by popularity</option>
                <option value="date">Sort by latest</option>
                <option value="brand">Sort by brand</option>
                <option value="price-asce">Sor by price: low to high</option>
                <option value="price-desc">Sor by price: high to low</option>
              </select>
            )}
          </div>
        </div>

        {renderList()}
      </main>
      <Footer />
    </>
  )
}
