import React, { useEffect } from "react"
import Header from "../components/Header/Header"
import Footer from "../components/footer/Footer"
import AboutPage from "../components/about/AboutPage"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { getMeta } from "../state/seoSlice"
import { useDispatch, useSelector } from "react-redux"

export default function About() {
  const dispatch = useDispatch()
  const { meta } = useSelector((state) => state.seo)
  var title = ""
  var description = ""
  for (let i = 0; i < meta.length; i++) {
    if (meta[i].url === "about-us") {
      title = meta[i].metaTitle
      description = meta[i].meteaDescription
    }
  }

  useEffect(() => {
    dispatch(getMeta())
  }, [dispatch])
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
      </HelmetProvider>
      <Header />
      <AboutPage />
      <Footer />
    </>
  )
}
