import React from "react"
import styles from "./AboutPage.module.scss"
import brand1 from "../../assets/brand1.png"
import brand2 from "../../assets/brand2.png"
import brand3 from "../../assets/brand3.png"
import brand4 from "../../assets/brand4.png"
import brand5 from "../../assets/brand5.png"
import brand6 from "../../assets/brand6.png"
import brand7 from "../../assets/brand7.png"

export default function AboutPage() {
  return (
    <main id={styles.main}>
      <section id={styles.section}>
        <div className={styles.text_part}>
          <h1 className={styles.heading}>
            CompuOne: Your Global Provider of
            <span className={styles.heading + " " + styles.grey}>
              {" "}
              Computer Products and Services
            </span>
          </h1>
          <p className={styles.base_text}>
            CompuOne is a leading provider of computer products and
            services worldwide. Founded in 1996, we offer a wide range
            of products and services to businesses and individuals
            alike. Our team of experienced professionals is dedicated
            to providing our customers with the best possible service.
          </p>
        </div>
        <div className={styles.image_container}>
          <img
            alt="Compuone_lebanon_cyprus"
            className={styles.image}
            src="https://www.shutterstock.com/image-photo/shot-two-businesswoman-working-together-600w-1820524997.jpg"
          />
        </div>
      </section>
      <hr className={styles.divider} />
      <section id={styles.section}>
        <div className={styles.part}>
          <div>
            <h1 className={styles.heading + " " + styles.limited}>
              Our Commitment to Excellence
            </h1>
          </div>

          <div>
            <p className={styles.sub_heading}>
              Our team is our greatest asset. We are a group of
              experienced and dedicated professionals who are
              passionate about providing our customers with the best
              possible service. We work together as a team to ensure
              that our customers are always satisfied.
            </p>
            <p className={styles.base_text + " " + styles.content}>
              We have a team of experts in all areas of computer
              technology, from sales and support to installation and
              maintenance. We are always up-to-date on the latest
              trends and technologies, so we can provide our customers
              with the best possible solutions. We are also committed
              to providing our customers with excellent customer
              service. We are always available to answer questions and
              help our customers with their needs. We believe that
              customer satisfaction is our top priority, and we are
              always striving to improve our services. If you are
              looking for a company with a strong team and a
              commitment to customer service, then CompuOne is the
              company for you. We are here to help you with all of
              your computer needs.
            </p>
          </div>
        </div>
        <div className={styles.part}>
          <div>
            <div className={styles.employee_profile}>
              <div className={styles.circular_image} />
              <div className={styles.employee_info}>
                <h1 className={styles.heading}>Fouad Shaw</h1>
                <p className={styles.base_text}>Founder & CEO</p>
              </div>
            </div>
          </div>

          <div>
            <p className={styles.quote}>
              "We understand that your business is your livelihood,
              and we want to help you succeed. That's why we offer
              products tailored to meet your specific needs.""
            </p>
          </div>
        </div>
      </section>
      <hr className={styles.divider} />
      <section id={styles.section}>
        <div className={styles.portfolio}>
          <div className={styles.portfolio_item}>
            <p className={styles.base_text}>
              Number of countries served
            </p>
            <h2 className={styles.numbers}>3+</h2>
          </div>
          <div className={styles.portfolio_item}>
            <p className={styles.base_text}>
              Number of years in business
            </p>
            <h2 className={styles.numbers}>10+</h2>
          </div>
          <div className={styles.portfolio_item}>
            <p className={styles.base_text}>
              Number of products sold
            </p>
            <h2 className={styles.numbers}>2K+</h2>
          </div>
        </div>
        {/* <div className={styles.clients}>
          <h1 className={styles.heading}>Our Clients</h1>
          <div className={styles.clients_grid}>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
            <div className={styles.client_item}></div>
          </div>
        </div> */}
        <section id={styles.brands}>
          <div className={styles.brands_header}>
            <p className={styles.heading}>Our Brands</p>
          </div>
          <hr color="black" style={{ height: 3, width: "100%" }} />
          <div className={styles.brand_container}>
            <img src={brand1} alt="Hanasis" />
            <img src={brand2} alt="INNO3D" />
            <img src={brand3} alt="PAN" />
            <img src={brand4} alt="Panda" />
          </div>
          <div className={styles.brand_container}>
            <img src={brand5} alt="Qbus" />
            <img src={brand6} alt="SilverStone" />
            <img src={brand7} alt="XVX" />
          </div>
          <hr color="black" style={{ height: 3, width: "100%" }} />
        </section>
      </section>
    </main>
  )
}
