import React, { useEffect, useState } from "react"
import styles from "./ProductsLists.module.scss"
import ProductItem from "./productItem/ProductItem"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchProducts,
  getAllProducts,
  incrementClicked,
  setProduct,
} from "../../state/productSlice"
import { useNavigate, useParams } from "react-router-dom"
import NotFound from "../../icons/NotFound"
import { sortProducts } from "../../helpers/Products"
import { formatUrl } from "../../helpers/Formats"

export default function ProductsList({ cat, subCat, sort, brand }) {
  const params = useParams()

  const [page, setPage] = useState(params.page || 1)
  const [pageCount, setPageCount] = useState(0)
  const dispatch = useDispatch()
  const { type, country, isLoading, accessLevel, currentCountry } = useSelector(
    (state) => state.auth
  )
  const { products, errorMessage } = useSelector((state) => state.product)
  const productState = useSelector((state) => state.product)

  useEffect(() => {
    setPage(params.page || 1)
  }, [params.page])

  useEffect(() => {
    const payload = {
      subCat: subCat,
      cat: cat,
      country: country || currentCountry,
      accessLevel: accessLevel,
      page: page,
      type: type,
    }
    if (currentCountry === "guest") {
      dispatch(getAllProducts(payload))
    } else if (currentCountry !== "") {
      dispatch(fetchProducts(payload)).then((res) => {
        setPageCount(res.payload.pagination.pageCount)
      })
    }
  }, [dispatch, cat, subCat, country, page, currentCountry, accessLevel, type])

  function handlePrevious() {
    if (params.category) {
      let category = formatUrl(cat)
      return `/${category}/${parseInt(page) - 1}`
    } else if (params.subCat) {
      let category = formatUrl(cat)
      let subCategory = formatUrl(subCat)

      return `/${category}/${subCategory}/${parseInt(page) - 1}`
    } else {
      return `/home/${parseInt(page) - 1}`
    }
  }

  function handleNext() {
    if (params.category) {
      let category = formatUrl(cat)
      return `/${category}/${parseInt(page) + 1}`
    } else if (params.subCat) {
      let category = formatUrl(cat)
      let subCategory = formatUrl(subCat)
      return `/${category}/${subCategory}/${parseInt(page) + 1}`
    } else {
      return `/home/${parseInt(page) + 1}`
    }
  }

  let hasProducts = products.length !== 0

  useEffect(() => {
    console.log("products", products)
  }, [products])

  let sortedProducts = sortProducts(products, sort, type)

  if (cat || subCat) {
    sortedProducts = sortedProducts.sort((a, b) =>
      a.isPinned === b.isPinned ? 0 : a.isPinned ? -1 : 1
    )
  }

  if (brand !== "all" && brand !== undefined) {
    sortedProducts = sortedProducts.filter(
      (p) => p.brand.toLowerCase() === brand.toLowerCase()
    )
  }

  if (errorMessage !== "") {
    return <div>Error</div>
  } else if (productState.isLoading && products.length === 0) {
    return <div>Loading...</div>
  } else {
    return (
      <ul className={hasProducts ? styles.products : styles.container}>
        {hasProducts ? (
          <li className={styles.product_items}>
            {Object.entries(sortedProducts).map(([key, p]) => {
              return <ProductItem key={key} product={p} />
            })}
          </li>
        ) : (
          <>
            <NotFound />
            <p className={styles.noProducts}>
              No products found in "{cat}" category{" "}
            </p>
          </>
        )}

        {pageCount !== 0 && (
          <footer id={styles.footer}>
            <div className={styles.mainC}>
              <p className={styles.f_pa_di}>
                {page}/{pageCount}
              </p>

              <div className={styles.btns}>
                <a
                  onClick={(e) => {
                    if (parseInt(page) === 1) {
                      e.preventDefault()
                    }
                  }}
                  href={
                    navigator.userAgent === "ReactSnap" ? "#" : handlePrevious()
                  }
                >
                  <div className={styles.prev}>Prev</div>
                </a>
                <a
                  onClick={(e) => {
                    if (parseInt(page) === pageCount) {
                      e.preventDefault()
                    }
                  }}
                  href={
                    navigator.userAgent === "ReactSnap" ? "#" : handleNext()
                  }
                >
                  <div className={styles.next}>Next</div>
                </a>
              </div>
            </div>
          </footer>
        )}
      </ul>
    )
  }
}
