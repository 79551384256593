import React from "react"
import styles from "./NewsItem.module.scss"

export default function NewsItem({ data }) {
  return (
    <div className={styles.news_item}>
      <div className={styles.image_container}>
        <img src={process.env.REACT_APP_BASE_URL + data.hrImage} />
      </div>
      <div className={styles.news_content}>
        <p className={styles.news_header}>{data.title}</p>
        <p className={styles.news_description}>{data.content}</p>
        <div className={styles.news_lower}>
          <span className={styles.news_category}>
            {data.category}
          </span>
          <a
            href={`/news/${data._id}`}
            className={styles.news_external_link}>
            Read more
          </a>
        </div>
      </div>
    </div>
  )
}
