import React, { useContext, useEffect } from "react"
import { PageContext } from "./context"
import Dashboard from "./dasboard/Dashboard"
import Products from "./products/Products"
import UsersManager from "./users/UsersManager"
import Analytics from "./analytics/Analytics"
import Seo from "./seo/Seo"
import Orders from "./orders/Orders"
import Categories from "./categories/Categories"
import Offers from "./offers/Offers"
import Notifications from "./notifications/Notifications"
import News from "./news/News"

export default function Component() {
  const { state } = useContext(PageContext)

  useEffect(() => {
    console.log(state)
  }, [state])

  let component = <Dashboard />

  if (state.page === "users") {
    component = <UsersManager />
  }
  if (state.page === "analytics") {
    component = <Analytics />
  }
  if (state.page === "products") {
    component = <Products />
  }
  if (state.page === "seo") {
    component = <Seo />
  }
  if (state.page === "orders") {
    component = <Orders />
  }
  if (state.page === "categories") {
    component = <Categories />
  }
  if (state.page === "offers") {
    component = <Offers />
  }
  if (state.page === "notifications") {
    component = <Notifications />
  }
  if (state.page === "news") {
    component = <News />
  }

  return component
}
