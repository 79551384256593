import React, { useRef, useState } from "react"
import styles from "./Editor.module.scss"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { clearNews, createNews } from "../../../../state/newsSlice"
import Placeholder from "../../../../icons/Placeholder"

export default function Editor({ setIsOpen }) {
  const { news } = useSelector((state) => state.news)

  const [vrImage, setVrImage] = useState(null)
  const [hrImage, setHrImage] = useState(null)
  const vrImageRef = useRef()
  const hrImageRef = useRef()

  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      vrImage: "",
      hrImage: "",
      title: "",
      description: "",
      category: "",
    },
  })

  function onSubmit(data) {
    console.log("data", data)
    dispatch(createNews(data))
  }

  return (
    <div className={styles.news_editor}>
      <div
        onClick={() => {
          vrImageRef.current.click()
        }}
        className={styles.vr_image}>
        <Controller
          control={control}
          name="vrImage"
          render={({ field: { onChange, value } }) => {
            return (
              <input
                onChange={(e) => {
                  const file = e.target.files[0]
                  onChange(file)
                  setVrImage(URL.createObjectURL(file))
                }}
                ref={vrImageRef}
                type="file"
                className={styles.input_file}
              />
            )
          }}
        />
        {vrImage ? (
          <img className={styles.image} src={vrImage} />
        ) : (
          <Placeholder size="80" fill="white" />
        )}
      </div>
      <div className={styles.content}>
        <div
          onClick={() => {
            hrImageRef.current.click()
          }}
          className={styles.hr_image}>
          <Controller
            control={control}
            name="hrImage"
            render={({ field: { onChange, value } }) => {
              return (
                <input
                  onChange={(e) => {
                    const file = e.target.files[0]
                    onChange(file)
                    setHrImage(URL.createObjectURL(file))
                  }}
                  ref={hrImageRef}
                  type="file"
                  className={styles.input_file}
                />
              )
            }}
          />

          {hrImage ? (
            <img className={styles.image} src={hrImage} />
          ) : (
            <Placeholder size="80" fill="white" />
          )}
        </div>
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value } }) => {
            return (
              <input
                className={styles.title_input}
                type="text"
                placeholder="Title"
                value={value}
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => {
            return (
              <textarea
                className={styles.description_input}
                placeholder="Content"
                value={value}
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="category"
          render={({ field: { onChange, value } }) => {
            return (
              <input
                className={styles.category_input}
                type="text"
                placeholder="category"
                value={value}
                onChange={onChange}
              />
            )
          }}
        />
        <div className={styles.actions}>
          <button
            onClick={() => {
              handleSubmit(onSubmit)()
              setIsOpen(false)
            }}>
            Save
          </button>
          <button
            onClick={() => {
              setIsOpen(false)
              dispatch(clearNews())
            }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
