import React, { useEffect, useState } from "react"
import styles from "./News.module.scss"
import NewsCard from "./newsCard/NewsCard"
import Editor from "./editor/Editor"
import { useDispatch, useSelector } from "react-redux"
import { getAllNews, setNews } from "../../../state/newsSlice"

export default function News() {
  const [isOpen, setIsOpen] = useState(false)

  const { newsList } = useSelector((state) => state.news)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllNews())
  }, [dispatch])

  useEffect(() => {
    console.log(newsList)
  }, [newsList])

  return (
    <section>
      {isOpen && (
        <div className={styles.main_overlay}>
          <div
            onClick={() => setIsOpen(false)}
            className={styles.overlay}
          />
          <Editor setIsOpen={setIsOpen} />
        </div>
      )}
      <div className={styles.header}>
        <h2 className={styles.title}>News</h2>
        <div onClick={() => setIsOpen(true)} className={styles.btn}>
          <p>Add</p>
        </div>
      </div>
      <div className={styles.news_list}>
        {Object.entries(newsList).map(([key, value]) => (
          <NewsCard key={key} data={value} />
        ))}
      </div>
    </section>
  )
}
