import axios from "axios"

export const createNewsRequest = async (token, payload) => {
  console.log(payload)

  try {
    const data = payload
    let formData = new FormData()
    formData.append("title", data.title)
    formData.append("content", data.content)
    formData.append("category", data.category)
    formData.append("images", data.hrImage)
    formData.append("images", data.vrImage)

    const res = await axios.post("news/create", formData)
    return res.data
  } catch (error) {
    throw error
  }
}

export const getAllNewsRequest = async () => {
  try {
    const res = await axios.get("news/all")
    return res.data
  } catch (error) {
    throw error
  }
}

export const getNewsByIdRequest = async (id) => {
  try {
    const res = await axios.get(`news/${id}`)
    return res.data
  } catch (error) {
    throw error
  }
}

export const removeNewsRequest = async (id) => {
  try {
    console.log(id)
    const res = await axios.get(`news/remove/${id}`)
    return res.data
  } catch (error) {
    throw error
  }
}
