import React from "react"
import styles from "./NewsCard.module.scss"
import { useDispatch } from "react-redux"
import { removeNews } from "../../../../state/newsSlice"

export default function NewsCard({ data }) {
  const dispatch = useDispatch()

  function dateFormat(date) {
    const newDate = new Date(date)
    return newDate.toDateString()
  }
  return (
    <div className={styles.news_item}>
      <div className={styles.news_title}>
        <h3>{data.title}</h3>
      </div>
      <div className={styles.news_date}>
        <p>{dateFormat(data.date)}</p>
      </div>
      <div
        onClick={() => {
          console.log(data._id)
          dispatch(removeNews(data._id))
        }}
        className={styles.news_btn}>
        <p>Delete</p>
      </div>
    </div>
  )
}
