import React, { useEffect, useRef, useState } from "react"
import styles from "./Header.module.scss"
import logo from "../../assets/logo.png"

import HeaderListItem from "./HeaderListItem"
import { useNavigate } from "react-router-dom"
import { useMediaPredicate } from "react-media-hook"
import Menu from "../../icons/Menu"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../state/authSlice"
import DrawerBody from "./Drawer/DrawerBody"
import Cart from "../../icons/Cart"
import CartCard from "./Cart/Cart"
import { deleteOrder } from "../../state/orderSlice"
import Instagram from "../../icons/Instagram"
import Facebook from "../../icons/Facebook"
import TikTokC from "../../icons/TikTokC"
import CashOnDelivery from "../../icons/CashOnDelivery"
import Visa from "../../icons/Visa"
import MasterCard from "../../icons/MasterCard"
import Youtube from "../../icons/Youtube"
import { formatUrl } from "../../helpers/Formats"

export default function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const [cartDrawer, setCartDrawer] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [countryToShow, setCountryToShow] = useState("guest")

  const dispatch = useDispatch()
  const lessThan850 = useMediaPredicate("(max-width: 850px)")

  const categoriesState = useSelector((state) => state.categories)
  const { token, currentCountry, country, accessLevel } = useSelector(
    (state) => state.auth
  )
  const { order, isLoading } = useSelector((state) => state.orders)
  const { contact } = useSelector((state) => state.seo)

  let navigate = useNavigate()

  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    let tempQuantity = 0
    if (!isLoading && order) {
      order.products.forEach((element) => {
        tempQuantity += element.quantity
      })
    }
    setQuantity(tempQuantity)
  }, [isLoading])

  const { categories } = categoriesState

  useEffect(() => {
    if (token) {
      setCountryToShow(country)
    } else {
      setCountryToShow(currentCountry)
    }
  }, [country, accessLevel, currentCountry])

  return (
    <header>
      <div className={styles.header_top}>
        <div className={styles.part}>
          <div>
            <CashOnDelivery size={20} fill="white" />
          </div>

          {countryToShow !== "Lebanon" &&
          countryToShow !== "United Arab Emirates" ? (
            <>
              <div>
                <Visa size={20} fill="white" />
              </div>
              <div>
                <MasterCard size={20} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.part}>{countryToShow}</div>
        <div className={styles.part}>
          <div>
            <a href={contact.youtube} target="_blank">
              <Youtube size={20} fill="white" />
            </a>
          </div>
          <div>
            <a href={contact.instagram} target="_blank">
              <Instagram size={20} fill="white" />
            </a>
          </div>
          <div>
            <a href={contact.facebook} target="_blank">
              <Facebook size={20} fill="white" />
            </a>
          </div>
          <div>
            <a href={contact.tiktok} target="_blank">
              <TikTokC href={contact.tiktok} size={20} fill="white" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.mainHeader}>
        <div className={styles.logo}>
          <a href="/">
            <img style={{ cursor: "pointer" }} src={logo} alt="COMPUONE" />
          </a>
        </div>

        <div className={styles.navBar_auth}>
          {!token && (
            <p
              onClick={() => navigate("/auth/sig")}
              className={styles.sign_in_text}
            >
              Sign In
            </p>
          )}
          <div
            className={styles.sign_button}
            onClick={() => {
              if (token) {
                if (order?.orderStatus === "Cart") {
                  localStorage.removeItem("cart_key")

                  dispatch(deleteOrder(order.orderId))
                }
                dispatch(logout())
                navigate("/auth/sig")
              } else {
                navigate("/auth/reg")
              }
            }}
          >
            <p>{token ? "Log out" : "Sign up"}</p>
          </div>
          <div
            onClick={() => {
              if (lessThan850) {
                setCartDrawer(!cartDrawer)
              }
            }}
            className={styles.cart_icon}
          >
            <Cart fill={"#184E93"} size={34} />

            <div className={styles.cart_size}>
              <span>{quantity}</span>
            </div>
            <div className={styles.cart_main}>
              {order && (
                <CartCard
                  isOpen={cartDrawer}
                  toggleDrawer={setCartDrawer}
                  order={order}
                />
              )}
            </div>
          </div>

          {lessThan850 && <Menu onClick={() => setIsOpen(true)} />}
        </div>
      </div>
      <div className={styles.navBar_products}>
        {!lessThan850 && !categoriesState.isLoading && (
          <ul className={styles.navBar_productsList}>
            {Object.entries(categories).map(([key, value]) => (
              <HeaderListItem
                url={
                  value.name === "Support"
                    ? `/support/drivers/1`
                    : `/${formatUrl(value.name)}/1`
                }
                categories={value.subCategories}
                title={value.name}
                key={key}
              />
            ))}
          </ul>
        )}
      </div>
      {lessThan850 && (
        <DrawerBody isOpen={isOpen} toggleDrawer={(s) => setIsOpen(s)} />
      )}
    </header>
  )
}
