import React from "react"
import { useNavigate } from "react-router-dom"
import styles from "./HeaderListItem.module.scss"
import { formatUrl } from "../../helpers/Formats"

export default function HeaderListItem({ title, categories, url }) {
  return (
    <li className={styles.listItem}>
      <a href={url} className={styles.textContainer}>
        <p className={styles.title}>{title}</p>
      </a>
      {/* {hover && ( */}
      <div className={styles.dropDown_menu}>
        <ul className={styles.dropDown_menu_list}>
          {Object.entries(categories).map(([key, cat]) => {
            let category = formatUrl(title)
            let subCat = formatUrl(cat)
            return (
              <li key={key} className={styles.dropDown_menu_listItem}>
                <a href={`/${category}/${subCat}/1`}>
                  <p>{cat}</p>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      {/* )} */}
    </li>
  )
}
